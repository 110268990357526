import React from 'react'
import { Card, Row, Col, Space, Typography, Image, Grid } from 'antd';
import ImgSample1 from '../../assets/img/document/personal-employment-1.jpeg';
import ImgSample2 from '../../assets/img/document/personal-employment-2.jpeg';
import { withTranslation } from 'react-i18next';

const { Text } = Typography;
const { useBreakpoint } = Grid;

function GetBreakPoint() {
    const screens = useBreakpoint()
    return screens;
}
  
export function InfoPersonalEmployment(props) {
    const { xs } = GetBreakPoint()
    const { t } = props
    return (
        <Card className='border-radius-6' bodyStyle={{ padding: xs ? 8 : 32 }}>
            <Row>
                <Col span={24}>
                    <Row>
                        <Col className='mb-16' span={24}>
                            <Text type="secondary">{t('profile.document.info.personalEmployment.title')}</Text>
                        </Col>
                        <Col className='text-align-center' span={12}>
                            <Image src={ImgSample1} width="100%" height={xs ? 200 : 400} preview={false} style={{ objectFit: 'contain' }} />
                        </Col>
                        <Col className='text-align-center' span={12}>
                            <Image src={ImgSample2} width="100%" height={xs ? 200 : 400} preview={false} style={{ objectFit: 'contain' }} />
                        </Col>
                        <Col xs={24} md={12}>
                            <Space direction="vertical" size={0}>
                                <Text type="secondary">{t('profile.document.info.ktp.title')}</Text>
                                <Text type="secondary">• {t('profile.document.info.ktp.first.normal1')}<Text className='dark-color' strong>{t('profile.document.info.ktp.first.bold')}</Text>{t('profile.document.info.ktp.first.normal2')}</Text>
                            </Space>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    )
}

export default withTranslation()(InfoPersonalEmployment)